<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("research-create.header") }}
      </h3>
      <ResearchForm
        :research="research"
        :onSubmit="onSubmit"
        :diagnostics="data.diagnostics"
        v-if="data != null"
      />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/research-create/submit-form";
import InitResearchCreateInteractor from "@/business/research-create/init-research-create";
import ResearchCreateScreenController from "@/adapters/controllers/screen-research-create";
import ResearchForm from "@/application/components/researches/ResearchForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "research-create",
  components: { ResearchForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initResearchCreate: null
      },
      data: null,
      isLoading: false,
      research: {
        name: "",
        dataSource: null,
        rMin: -0.7,
        rMax: 0.7,
        samplesMin: 3,
        samplesRequired: 1000,
        globalRateMin: 0.9,
        p2Max: 0.8,
        p3Data: { delimiters: [] },
        labels: [],
        isPublished: false,
        figure: null,
        titlePtBr: "",
        titleEnUs: "",
        titleEsEs: ""
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ResearchCreateScreenController);

    //{ INTERACTORS
    this.interactors.initResearchCreate = this.$injector.get(
      InitResearchCreateInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initResearchCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.research);
    }
  }
};
</script>

<style lang="scss"></style>
